import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);

  const [subMenuActive] = useState();

  const handleIconClick = () => {
    setIsMainMenuOpen(!isMainMenuOpen);
  };

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };
  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <header className="header-area header-wide">
      <div className="main-header d-none d-lg-block">
        <div className="header-main-area sticky">
          <div className="container-fluid">
            <div className="row align-items-center position-relative">
              <div className="col-12 col-lg-2 col-md-3">
                <div className="logo">
                  <Link to="/">
                    <img src="/assets/img/logo/patola-palace-logo.png" alt="Patola Palace Logo" />
                  </Link>
                </div>
              </div>

              <div className="col-12 col-md-8 col-lg-7 position-static d-flex justify-content-center align-items-center">
                <div className="main-menu-area">
                  <div className="main-menu">
                    <nav className="desktop-menu">
                      <ul>
                        <li className="active">
                          <Link to="/">
                            Home
                          </Link> 
                        </li>
                        <li>
                          <Link to="/product">Shop</Link>
                        </li><li>
                          <Link to="/contact">Contact us</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- mobile header start --> */}
      <div className="mobile-header d-lg-none d-md-block sticky">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="mobile-main-header">
                <div className="mobile-logo">
                  <Link to="/">
                    <img src="/assets/img/logo/patola-palace-logo.png" alt="Patola Palace Logo" />
                  </Link>
                </div>
                <div className="mobile-menu-toggler">
                  <div
                    className="mini-cart-wrap"
                    style={{ marginRight: "10px" }}
                  ></div>
                  <div className="mini-cart-wrap"></div>
                  <button
                    className="mobile-menu-btn"
                    onClick={handleMenuToggle}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`off-canvas-wrapper ${isMenuOpen ? "open" : ""}`}>
        <div className="off-canvas-overlay" onClick={handleMenuToggle}></div>
        <div className="off-canvas-inner-content">
          <div className="btn-close-off-canvas">
            <i className="pe-7s-close" onClick={handleMenuClose}></i>
          </div>

          <div className="off-canvas-inner">
            <div className="mobile-navigation">
              <nav>
                <ul className="mobile-menu">
                  {/* <li
                    className={`menu-item-has-children ${
                      isMainMenuOpen ? "active" : ""
                    }`}
                  >
                    <Link to="/">Home</Link>
                    <ul
                      className="megamenu dropdown"
                      style={{ display: isMainMenuOpen ? "block" : "none" }}
                    >
                      <li
                        className={`mega-title menu-item-has-children ${
                          subMenuActive ? "active" : ""
                        }`}
                      >
                        <ul className="dropdown">
                          <li>
                            <Link to="/product">Shop</Link>
                          </li>
                          <li>
                            <Link to="/contact">Contact Us</Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <span className="menu-expand" onClick={handleIconClick}>
                      <i></i>
                    </span>
                  </li> */}
                  <li className="menu-item-has-children">
                            <Link to="/product">Home</Link>
                  </li>
                  <li className="menu-item-has-children">
                            <Link to="/product">Shop</Link>
                          </li>
                  <li className="menu-item-has-children">
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="mobile-settings"></div>
          </div>
        </div>
      </div>
    </header>
  );
}
